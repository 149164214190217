import axios from "../config/axios";

class ForeCastService {
  async getData() {
    try {
      // const res = await axios.get('http://localhost:5000/api/forecast/bmkg');
      const res = await axios.get("/api/bmkg");
      return res;
    } catch (error) {
      return error;
    }
  }

  async getDataAwlrOrong() {
    try {
      const res = await axios.get(
        "https://inventory.bwsnt1.net/api/meninting/tatonas",
        {
          headers: {
            Authorization: "Basic c3VydmV5c2lzZGE6cGFzczJzaXNkYXN1cnZleQ==",
          },
        }
      );
      console.log("tatonas", res);
      let orong =
        res.data.data["Balai Wilayah Sungai Nusa Tenggara I"].hardware[3]
          .sensor["Tinggi Muka Air"].value_actual;
      return orong;
    } catch (error) {
      return error;
    }
  }

  async getDataAwlrBlencong() {
    try {
      const res = await axios.get(
        "https://inventory.bwsnt1.net/api/meninting/hidrologi",
        {
          headers: {
            Authorization: "Basic c3VydmV5c2lzZGE6cGFzczJzaXNkYXN1cnZleQ==",
          },
        }
      );
      console.log("hidrologi", res);
      let tma = res.data[22].wl;
      return tma;
    } catch (error) {
      return error;
    }
  }
}

export default new ForeCastService();

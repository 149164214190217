const lokasi = (segement, lat, lng) => {
  return {
    segement: segement,
    lat: lat,
    lng: lng,
  };
};

export const dataLokasi = [
  lokasi('M - 3', '-8.528746583334872', '116.1517463874409'),
  lokasi('M - 4', '-8.530840738471117', '116.15114523947729'),
  lokasi('M - 6', '-8.534864276837858', '116.14993215523486'),
  lokasi('M - 8', '-8.539186850461576', '116.14999247895298'),
  lokasi('M - 9', '-8.540651934319289', '116.148401459428'),
  lokasi('M - 10', '-8.542765561150889', '116.14789907132317'),
  lokasi('M - 11', '-8.544962708753195', '116.14722305779564'),
  lokasi('M - 12', '-8.547043826882424', '116.14665901531271'),
  lokasi('M - 13', '-8.549161253159342', '116.1457818966349'),
  lokasi('M - 15', '-8.55162813949184', '116.14202708207804'),
  lokasi('M - 17', '-8.553627003610313', '116.13836542499546'),
  lokasi('M - 19', '-8.555001624398873', '116.13437104182869'),
  lokasi('M - 20', '-8.55395110388483', '116.13167191537896'),
  lokasi('M - 21', '-8.55228962939168', '116.13124088882434'),
  lokasi('M - 23', '-8.552345979581974', '116.12744345442982'),
  lokasi('M - 24', '-8.551321242960327', '116.12556786441688'),
  lokasi('M - 25', '-8.552192827411147', '116.12360638624384'),
  lokasi('M - 27', '-8.550164126844919', '116.12033888132831'),
  lokasi('M - 29', '-8.5491723412031', '116.1159199545668'),
  lokasi('M - 31', '-8.549059154719856', '116.11187444504147'),
  lokasi('M - 33', '-8.548043067844041', '116.1080811148812'),
  lokasi('M - 35', '-8.548294519143944', '116.10321915679721'),
  lokasi('M - 37', '-8.547385167770017', '116.09895361007631'),
  lokasi('M - 39', '-8.543798703533785', '116.09725143899911'),
  lokasi('M - 40', '-8.543997454828432', '116.09576032099751'),
  lokasi('M - 42', '-8.54650943810407', '116.09271104523827'),
  lokasi('M - 43', '-8.547623506134554', '116.09360381937994'),
  lokasi('M - 44', '-8.549123175122812', '116.09146699420396'),
  lokasi('M - 45', '-8.548179873242141', '116.08988431526885'),
  lokasi('M - 47', '-8.544852327146259', '116.0876695371872'),
  lokasi('M - 49', '-8.544911479464126', '116.08379312365119'),
  lokasi('M - 51', '-8.547787972846631', '116.0802968988165'),
  lokasi('M - 53', '-8.551818885182264', '116.07832771399346'),
  lokasi('M - 55', '-8.553302341310854', '116.07401000925685'),
  lokasi('M - 59', '-8.551347039632656', '116.07035107293964'),
];

// import { debit } from '../data/debit';

const matrix = (
  segment,
  lat,
  lng,
  curah_hujan,
  q_hitung,
  q_awlr,
  q_fbc,
  persen_fbc,
  status,
  color,
  text_color,
  radius
) => {
  return {
    segment: segment,
    lat: lat,
    lng: lng,
    curah_hujan: curah_hujan,
    q_hitung: q_hitung,
    q_awlr: q_awlr,
    q_fbc: q_fbc,
    persen_fbc: persen_fbc,
    status: status,
    color: color,
    text_color: text_color,
    radius: radius,
  };
};

const fQhitungTetap = (lokasi, curah_hujan, debit) => {
  let qHitung = [0, 0, 0];
  for (let i = 0; i < lokasi.length; i++) {
    if (i === 0 || i === 1) {
      let dataDebit = debit[0];
      for (let j = 0; j < dataDebit.length; j++) {
        if (curah_hujan === dataDebit[j].curah_hujan) {
          qHitung[0] = dataDebit[j].debit;
        }
      }
    } else if (i === 2) {
      let dataDebit = debit[1];
      for (let j = 0; j < dataDebit.length; j++) {
        if (curah_hujan === dataDebit[j].curah_hujan) {
          qHitung[1] = dataDebit[j].debit;
        }
      }
    } else if (i === 3) {
      let dataDebit = debit[2];
      for (let j = 0; j < dataDebit.length; j++) {
        if (curah_hujan === dataDebit[j].curah_hujan) {
          qHitung[2] = dataDebit[j].debit;
        }
      }
    }
  }

  return qHitung;
};

const fQHitung = (indexLokasi, qHitungTetap) => {
  let qHitung = 0;

  if (indexLokasi > 1 && indexLokasi < 19) {
    qHitung =
      qHitungTetap[0] +
      ((qHitungTetap[1] - qHitungTetap[0]) * (indexLokasi - 1)) / 18;
  } else if (indexLokasi > 19 && indexLokasi < 34) {
    qHitung =
      qHitungTetap[1] +
      ((qHitungTetap[2] - qHitungTetap[1]) * (indexLokasi - 19)) / 15;
  }

  return qHitung;
};

const fQawlr = (
  indexLokasi,
  prevQawlr,
  tmaAwlrOrong,
  tmaAwlrBlencong,
  additionalparam
) => {
  // console.log('index lokasi', indexLokasi);
  // console.log('tma Awlr Orong', tmaAwlrOrong);
  // console.log('tma Awlr Belencong', tmaAwlrBlencong);
  if (indexLokasi === 0) {
    return tmaAwlrOrong;
  } else if (indexLokasi > 0 && indexLokasi < 19) {
    return prevQawlr + (tmaAwlrBlencong - tmaAwlrOrong) / 19;
  } else if (indexLokasi === 19) {
    return tmaAwlrBlencong;
  } else if (indexLokasi > 19 && indexLokasi < 35) {
    return prevQawlr + (tmaAwlrBlencong - additionalparam);
  }
};

const fStatus = (persen) => {
  // console.log('status persen', persen);
  if (persen < 70) {
    return {
      status: 'Normal',
      color: 'blue',
      text_color: 'white',
      radius: 80,
    };
  } else if (persen >= 10 && persen <= 80) {
    return {
      status: 'Siaga',
      color: 'yellow',
      text_color: 'black',
      radius: 90,
    };
  } else if (persen >= 80 && persen <= 90) {
    return {
      status: 'Waspada',
      color: 'orange',
      text_color: 'white',
      radius: 100,
    };
  } else if (persen >= 90 && persen <= 100) {
    return {
      status: 'Awas',
      color: 'brown',
      text_color: 'white',
      radius: 110,
    };
  } else if (persen > 100) {
    return {
      status: 'Melimpas',
      color: 'red',
      text_color: 'white',
      radius: 120,
    };
  }
};

export const formula = (
  lokasi,
  curah_hujan,
  debit,
  tmaAwlrOrong,
  tmaAwlrBlencong,
  dataBankFull,
  debitReduksiBanjir,
  optionBendungan,
  optionForecast
) => {
  let dataMatrix = [];

  let qHitungTetap = fQhitungTetap(
    [lokasi[0], lokasi[1], lokasi[19], lokasi[34]],
    curah_hujan,
    debit
  );

  // console.log('q hitung tetap', qHitungTetap);

  let qAwlr = 0;
  let otherParam = 0;

  for (let i = 0; i < lokasi.length; i++) {
    let qHitung = 0;
    if (i === 0 || i === 1) {
      qHitung = qHitungTetap[0];
    } else if (i === 19) {
      qHitung = qHitungTetap[1];
    } else if (i === 34) {
      qHitung = qHitungTetap[2];
    } else {
      qHitung = fQHitung(i, qHitungTetap);
    }

    otherParam = i > 19 ? dataMatrix[18].q_awlr : 0;

    qAwlr = fQawlr(i, qAwlr, tmaAwlrOrong, tmaAwlrBlencong, otherParam);
    let qFbc = dataBankFull[i].estimate;

    dataMatrix.push(
      matrix(
        lokasi[i].segement,
        lokasi[i].lat,
        lokasi[i].lng,
        curah_hujan,
        qHitung === 0 ? qAwlr : qHitung,
        qAwlr,
        qFbc
      )
    );
  }

  for (let i = 0; i < dataMatrix.length; i++) {
    // console.log('q awlr', dataMatrix[i].q_awlr);
    if (optionForecast === 'Near') {
      let mQhitung = dataMatrix[i].q_awlr;
      if (optionBendungan === 'Dengan Bendungan') {
        mQhitung = mQhitung - debitReduksiBanjir;
      }

      let persen = (mQhitung / dataMatrix[i].q_fbc) * 100;

      dataMatrix[i].persen_fbc = persen < 0 ? 0 : persen;
    } else if (optionForecast === 'Forecast') {
      let mQawlr = dataMatrix[i].q_hitung;
      if (optionBendungan === 'Dengan Bendungan') {
        mQawlr = mQawlr - debitReduksiBanjir;
      }
      let persen = (mQawlr / dataMatrix[i].q_fbc) * 100;
      dataMatrix[i].persen_fbc = persen < 0 ? 0 : persen;
    }

    // console.log('persen fbc', dataMatrix[i].persen_fbc);

    dataMatrix[i].status = fStatus(dataMatrix[i].persen_fbc).status;
    dataMatrix[i].color = fStatus(dataMatrix[i].persen_fbc).color;
    dataMatrix[i].text_color = fStatus(dataMatrix[i].persen_fbc).text_color;
    dataMatrix[i].radius = fStatus(dataMatrix[i].persen_fbc).radius;
  }

  return dataMatrix;
};

import './App.css';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import MainLayout from './components/layouts/MainLayout';

function App() {
  return (
    <div>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<HomePage />}></Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;

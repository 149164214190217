function CardForecastVert({ data }) {
  return (
    <div className="m-2 rounded-lg bg-gradient-to-b from-blue-300 to-blue-400 shadow-md">
      <div className="flex items-center justify-between p-6 text-black">
        <div className="mr-12">
          <div className="text-xl font-bold">{data.waktu}</div>
          <div className="mt-4">
            <img src={data.gambar} alt="" className="h-24 w-24" />
          </div>
          <div className="mt-4 whitespace-nowrap text-lg font-semibold">
            {data.keterangan}
          </div>
        </div>

        <div className="mt-8 max-w-md">
          <div className="flex w-full justify-between gap-8">
            <div className="text-lg font-semibold">{data.suhu}</div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                className="h-6 w-6"
                fill="currentColor"
              >
                <path d="M160 64c-26.5 0-48 21.5-48 48V276.5c0 17.3-7.1 31.9-15.3 42.5C86.2 332.6 80 349.5 80 368c0 44.2 35.8 80 80 80s80-35.8 80-80c0-18.5-6.2-35.4-16.7-48.9c-8.2-10.6-15.3-25.2-15.3-42.5V112c0-26.5-21.5-48-48-48zM48 112C48 50.2 98.1 0 160 0s112 50.1 112 112V276.5c0 .1 .1 .3 .2 .6c.2 .6 .8 1.6 1.7 2.8c18.9 24.4 30.1 55 30.1 88.1c0 79.5-64.5 144-144 144S16 447.5 16 368c0-33.2 11.2-63.8 30.1-88.1c.9-1.2 1.5-2.2 1.7-2.8c.1-.3 .2-.5 .2-.6V112zM208 368c0 26.5-21.5 48-48 48s-48-21.5-48-48c0-20.9 13.4-38.7 32-45.3V200c0-8.8 7.2-16 16-16s16 7.2 16 16V322.7c18.6 6.6 32 24.4 32 45.3z" />
              </svg>
            </div>
          </div>

          <div className="mt-2 flex w-full justify-between gap-8">
            <div className="text-lg font-semibold">{data.kelembaban}</div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                className="h-6 w-6"
                fill="currentColor"
              >
                <path d="M192 496c97.2 0 176-78.8 176-176c0-74.1-102.3-230.6-150.9-300.7c-12.3-17.7-37.8-17.7-50.1 0C118.3 89.4 16 245.9 16 320c0 97.2 78.8 176 176 176zM112 320c0 44.2 35.8 80 80 80c8.8 0 16 7.2 16 16s-7.2 16-16 16c-61.9 0-112-50.1-112-112c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
              </svg>
            </div>
          </div>

          <div className="mt-2 flex w-full justify-between gap-8">
            <div className="flex flex-col items-start">
              <div className="text-lg font-semibold">
                {data.kecepatan_angin}
              </div>
              <div className="text-sm font-normal">{data.arah_angin}</div>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="h-6 w-6"
                fill="currentColor"
              >
                <path d="M288 32c0 17.7 14.3 32 32 32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H352c53 0 96-43 96-96s-43-96-96-96H320c-17.7 0-32 14.3-32 32zm64 352c0 17.7 14.3 32 32 32h32c53 0 96-43 96-96s-43-96-96-96H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H384c-17.7 0-32 14.3-32 32zM128 512h32c53 0 96-43 96-96s-43-96-96-96H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H160c17.7 0 32 14.3 32 32s-14.3 32-32 32H128c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardForecastVert;

function NavBar() {
  return (
    <div className="flex h-16 w-full border-b shadow-md">
      <div className="h-full w-full px-8">
        <div className="flex h-full w-full items-center justify-between">
          <div>
            <div className="flex items-center gap-4">
              <img src="/kemenpu.jpg" alt="" className="h-12 rounded" />

              <div>
                <div className="text-xl font-bold uppercase">
                  Flood Early Warning System Sungai Meninting
                </div>
                <div className="font-bold">BWS Nusa Tenggara I</div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;

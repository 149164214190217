export const bank_full = (estimate) => {
  return {
    estimate: estimate,
  };
};

export const dataBankFull = [
  bank_full(1260),
  bank_full(1235),
  bank_full(1854),
  bank_full(6018),
  bank_full(404),
  bank_full(8862),
  bank_full(1885),
  bank_full(1148),
  bank_full(7232),
  bank_full(1352),
  bank_full(488),
  bank_full(410),
  bank_full(408),
  bank_full(318),
  bank_full(847),
  bank_full(1257),
  bank_full(367),
  bank_full(532),
  bank_full(842),
  bank_full(882),
  bank_full(986),
  bank_full(639),
  bank_full(424),
  bank_full(622),
  bank_full(354),
  bank_full(684),
  bank_full(448),
  bank_full(804),
  bank_full(855),
  bank_full(952),
  bank_full(875),
  bank_full(762),
  bank_full(790),
  bank_full(3085),
  bank_full(1640),
];

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyApgYVgcNHTOQOJkl4QMtwFzMOSn2MXyaA',
  authDomain: 'simulasi-sungai-meninting.firebaseapp.com',
  databaseURL:
    'https://simulasi-sungai-meninting-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'simulasi-sungai-meninting',
  storageBucket: 'simulasi-sungai-meninting.appspot.com',
  messagingSenderId: '1087698254159',
  appId: '1:1087698254159:web:b12d927c786c797559ed3a',
  measurementId: 'G-GTMDJNSDG1',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };

export const debit = (curah_hujan, hujan, debit) => {
  return {
    curah_hujan: curah_hujan,
    hujan: hujan,
    debit: debit,
  };
};

export const dataDebitOrongAtas = [
  debit('Hujan Sangat Ringan', 5, 7.857),
  debit('Hujan Ringan', 20, 31.4290028479139),
  debit('Hujan Sedang', 50, 78.5725071197847),
  debit('Hujan Lebat', 100, 157.145014239569),
  debit('Hujan Petir', 100, 157.145014239569),
  debit('Hujan Sangat Lebat', 150, 235.717521359354),
  debit('Hujan Ekstrem', 200, 314.290028479139),
];

export const dataDebitBelencong = [
  debit('Hujan Sangat Ringan', 5, 10.311),
  debit('Hujan Ringan', 20, 41.2421725590504),
  debit('Hujan Sedang', 50, 103.105431397626),
  debit('Hujan Lebat', 100, 206.210862795252),
  debit('Hujan Petir', 100, 206.210862795252),
  debit('Hujan Sangat Lebat', 150, 309.316294192878),
  debit('Hujan Ekstrem', 200, 412.421725590504),
];

export const dataDebitDasMeninting = [
  debit('Hujan Sangat Ringan', 5, 16.734),
  debit('Hujan Ringan', 20, 66.935),
  debit('Hujan Sedang', 50, 167.337),
  debit('Hujan Lebat', 100, 334.674),
  debit('Hujan Petir', 100, 334.674),
  debit('Hujan Sangat Lebat', 150, 502.01),
  debit('Hujan Ekstrem', 200, 669.349),
];

import { Outlet } from 'react-router-dom';
import Footer from '../molecul/Footer';
import NavBar from '../molecul/NavBar';

function MainLayout() {
  return (
    <div>
      <NavBar />
      <div className="mx-auto px-8 md:max-w-7xl xl:max-w-full">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default MainLayout;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { Circle, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import EmblaCarousel from "../components/slider/EmblaCarousel";
import { dataBankFull } from "../data/bank_full";
import {
  dataDebitBelencong,
  dataDebitDasMeninting,
  dataDebitOrongAtas,
} from "../data/debit";
import { dataLokasi } from "../data/lokasi";
import { formula } from "../formula/matrix";
import ForecastService from "../services/ForecastService";
import { getDatabase, onValue, ref } from "firebase/database";
import { app } from "../config/firebase";
// import io from 'socket.io-client';

// const socket = io();

function HomePage() {
  const [forecast, setForecast] = useState([]);
  const [activeForecast, setActiveForecast] = useState(0);
  const [activeTime, setActiveTime] = useState(0);
  const [timeForecast, setTimeForecast] = useState([]);
  const [statusCuaca, setStatusCuaca] = useState("");
  const [dataMatrix, setDataMatrix] = useState([]);
  const [optionBendungan, setOptionBendungan] = useState("Tanpa Bendungan");
  const [optionForecast, setOptionForecast] = useState("Near");

  const [tmaAwlrOrong, setTmaAwlrOrong] = useState(0); //55.44
  const [tmaAwlrBlencong, setTmaAwlrBlencong] = useState(0); //457.06
  const [debitReduksiBanjir, setDebitReduksiBanjir] = useState(95.19);

  const parentMapRef = useRef();
  const mapRef = useRef();
  const markerRef = useRef([]);

  const [parentMapHeight, setParentMapHeight] = useState("700px");

  const db = getDatabase(app);

  // const [isConnected, setIsConnected] = useState(socket.connected);

  let dataSegment = dataLokasi;
  let dataDebit = [
    dataDebitOrongAtas,
    dataDebitBelencong,
    dataDebitDasMeninting,
  ];
  let dataBankF = dataBankFull;

  // useEffect(() => {
  //   socket.on('connect', () => {
  //     console.log('connect');
  //     setIsConnected(true);
  //   });

  //   socket.on('disconnect', () => {
  //     setIsConnected(false);
  //   });

  //   // socket.on('pong', () => {
  //   //   setLastPong(new Date().toISOString());
  //   // });

  //   return () => {
  //     socket.off('connect');
  //     socket.off('disconnect');
  //     socket.off('pong');
  //   };
  // }, []);

  useEffect(() => {
    const starCountRef = ref(db, "telemetri");
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      console.log("firebase data telemetri", data);
      setTmaAwlrOrong(data.orong.debit.toFixed(2));
      setTmaAwlrBlencong(data.blencong.debit.toFixed(2));
    });
  }, []);

  useEffect(() => {
    let pHeight = parentMapRef.current.clientHeight;
    console.log("parent map height", pHeight);
    setParentMapHeight(pHeight + "px");
    getDataForecast();

    getAwlrOrong();
    getAwlrBlencong();

    let interval = setInterval(() => {
      getAwlrOrong();
      getAwlrBlencong();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let map = mapRef.current;
    console.log("map ref", map);
  }, [parentMapHeight]);

  useEffect(() => {
    let dataFormula = formula(
      dataSegment,
      statusCuaca,
      dataDebit,
      parseFloat(tmaAwlrOrong || 0),
      parseFloat(tmaAwlrBlencong || 0),
      dataBankF,
      debitReduksiBanjir,
      optionBendungan,
      optionForecast
    );
    // console.log("data formula", dataFormula);
    setDataMatrix(dataFormula);
  }, [
    statusCuaca,
    optionBendungan,
    optionForecast,
    tmaAwlrOrong,
    tmaAwlrBlencong,
    debitReduksiBanjir,
  ]);

  const getDataForecast = async () => {
    try {
      const res = await ForecastService.getData();
      console.log("res", res);
      setForecast(res.data.data.forecast);
      setStatusCuaca(res.data.data.forecast[0].cuaca[0].keterangan);
      setTimeForecast(res.data.data.forecast[0].cuaca);
      // setStatusCuaca('Hujan Ringan');
    } catch (error) {
      console.error(error.reponse);
    }
  };

  const onClickShowMarker = (position, index) => {
    const map = mapRef.current;
    if (!map) {
      return;
    }

    map.flyTo(position, 14);

    const marker = markerRef.current[index];

    if (marker) {
      marker.openPopup();
    }

    console.log("marker", marker);
  };

  const getAwlrOrong = async () => {
    try {
      const res = await ForecastService.getDataAwlrOrong();
      console.log("orong", res);
      // let tma =
      //   res.data.data.awlr_orong.sensor["Tinggi Muka Air"].value_actual / 100;
      let tma = res / 100;
      // console.log("tma", tma);
      // let ytma = tma - 0.53;
      // console.log("ytma", ytma);

      // let xtma = Math.pow(tma - 0.33, 2.388);
      // console.log("xtma", xtma);

      let debit = 22.491 * Math.pow(tma - 0.33, 2.388);
      console.log("debit orong", debit);
      setTmaAwlrOrong(debit.toFixed(2));
    } catch (error) {
      console.error(error.reponse);
    }
  };

  const getAwlrBlencong = async () => {
    try {
      const res = await ForecastService.getDataAwlrBlencong();
      console.log("blencong", res);
      // let tma = res.data.data.awlr_belencong.wl;
      let tma = res;

      // let tma =
      //   res.data.data.awlr_orong.sensor['Tinggi Muka Air'].value_actual / 100;
      // console.log('tma', tma);
      tma = parseFloat(tma);
      console.log("tma belencong", tma);
      let debit = 0;
      if (tma < 2.5) {
        debit = 4.258 * Math.pow(tma + 0.56, 2.524);
        console.log("debit belencong", debit);
      } else if (tma > 2.4) {
        debit = 10.9618 * Math.pow((tma - 0.3) ^ 2.4105);
        console.log("debit belencong", debit);
      }

      setTmaAwlrBlencong(debit.toFixed(2));
    } catch (error) {
      console.error(error.reponse);
    }
  };

  const checkNumber = (value) => {
    if (!isNaN(value)) {
      return value;
    }
    return 0;
  };

  return (
    <div className="py-8">
      <div className="flex gap-4">
        {forecast.map((d, i) => {
          return (
            <div
              key={i}
              className={`cursor-pointer rounded-md border px-4 py-1.5 text-sm font-semibold shadow-md hover:border-blue-500 hover:bg-blue-600 hover:text-white ${
                activeForecast === i
                  ? "border-blue-600 bg-blue-600 text-white shadow-blue-500"
                  : "bg-white"
              }`}
              onClick={() => {
                setActiveForecast(i);
                setActiveTime(0);
                // console.log(d.cuaca[0].keterangan);
                setStatusCuaca(d.cuaca[0].keterangan);
                setTimeForecast(d.cuaca);
              }}
            >
              {d.hari_tanggal}
            </div>
          );
        })}
      </div>

      <div className="mt-8">
        {forecast.map((d, i) => {
          // return (
          //   <div key={i} className="mt-4">
          //     <div className="grid grid-cols-4 gap-4">
          //       {d.cuaca.map((c, j) => {
          //         return <CardForecast key={j} data={c} />;
          //       })}
          //     </div>
          //   </div>
          // );

          return activeForecast === i ? (
            // <div key={i} className="-m-2 mt-4">
            //   <div className="grid grid-cols-4">
            //     {d.cuaca.map((c, j) => {
            //       return <CardForecast key={j} data={c} />;
            //     })}
            //   </div>

            // </div>
            <EmblaCarousel key={i} slides={d.cuaca} />
          ) : (
            ""
          );
        })}
      </div>

      <div className="mt-8 w-full">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-1">
            <label
              htmlFor="country"
              className="block text-sm font-semibold text-gray-700"
            >
              Waktu
            </label>
            <select
              id="country"
              name="country"
              autoComplete="country-name"
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              defaultValue={timeForecast.length > 0 && timeForecast[0].waktu}
              onChange={(e) => {
                // setActiveTime(j);
                setStatusCuaca(e.target.value);
              }}
            >
              {/* <option>Waktu</option> */}

              {timeForecast.map((d, i) => {
                return (
                  <option key={i} value={d.keterangan}>
                    {d.waktu}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="col-span-6 sm:col-span-1">
            <label
              htmlFor="country"
              className="block text-sm font-semibold text-gray-700"
            >
              Dengan/Tanpa Bendungan
            </label>
            <select
              id="country"
              name="country"
              autoComplete="country-name"
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              defaultValue={optionBendungan}
              onChange={(e) => {
                // setActiveTime(j);
                setOptionBendungan(e.target.value);
              }}
            >
              <option value="Dengan Bendungan">Dengan Bendungan</option>
              <option value="Tanpa Bendungan">Tanpa Bendungan</option>
            </select>
          </div>

          <div className="col-span-6 sm:col-span-1">
            <label
              htmlFor="country"
              className="block text-sm font-semibold text-gray-700"
            >
              Realisasi/Forecast
            </label>
            <select
              id="country"
              name="country"
              autoComplete="country-name"
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              onChange={(e) => {
                // setActiveTime(j);
                setOptionForecast(e.target.value);
              }}
            >
              <option value="Near">Realisasi</option>
              <option value="Forecast">Forecast</option>
            </select>
          </div>

          <div className="col-span-6 sm:col-span-1">
            <label
              htmlFor="country"
              className="block text-sm font-semibold text-gray-700"
            >
              Debit AWLR Orong (m<sup>3</sup>/dt)
            </label>
            <input
              type="text"
              name="first-name"
              id="first-name"
              autoComplete="given-name"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              // defaultValue={tmaAwlrOrong}
              value={tmaAwlrOrong}
              onChange={(e) => {
                console.log(checkNumber(e.target.value));

                // let v = parseFloat(e.target.value) || 0;
                // setTmaAwlrOrong(v);
                // setTmaAwlrOrong(e.target.value);
                setTmaAwlrOrong(checkNumber(e.target.value));
              }}
            />
          </div>

          <div className="col-span-6 sm:col-span-1">
            <label
              htmlFor="country"
              className="block text-sm font-semibold text-gray-700"
            >
              Debit AWLR Blencong (m<sup>3</sup>/dt)
            </label>
            <input
              type="text"
              name="last-name"
              id="last-name"
              autoComplete="family-name"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              // defaultValue={tmaAwlrBlencong}
              value={tmaAwlrBlencong}
              onChange={(e) => {
                console.log(checkNumber(e.target.value));
                // console.log(parseFloat(e.target.value));
                // let v = parseFloat(e.target.value);
                // setTmaAwlrBlencong(v);
                // setTmaAwlrBlencong(e.target.value);
                setTmaAwlrBlencong(checkNumber(e.target.value));
              }}
            />
          </div>

          <div className="col-span-6 sm:col-span-1">
            <label
              htmlFor="country"
              className="block text-sm font-semibold text-gray-700"
            >
              Debit Reduksi Banjir (m<sup>3</sup>/dt)
            </label>
            <input
              type="text"
              name="last-name"
              id="last-name"
              autoComplete="family-name"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={
                optionBendungan === "Tanpa Bendungan" ? 0 : debitReduksiBanjir
              }
              onChange={(e) => {
                // let v = parseFloat(e.target.value) || 0;
                setDebitReduksiBanjir(checkNumber(e.target.value));
              }}
            />
          </div>
        </div>
      </div>

      <div className="mt-8 flex gap-4">
        {/* <div className="h-full w-60">
          <div></div>

          {forecast.map((d, i) => {
            return activeForecast === i
              ? d.cuaca.map((dc, j) => {
                  return (
                    <button
                      key={j}
                      className={`mb-4 w-full rounded-lg border py-2 font-semibold shadow-md ${
                        j === activeTime
                          ? 'border-blue-500 bg-blue-600 text-white shadow-blue-500'
                          : 'bg-white text-black'
                      }`}
                      onClick={() => {
                        setActiveTime(j);
                        setStatusCuaca(dc.keterangan);
                      }}
                    >
                      {dc.waktu}
                    </button>
                  );
                })
              : '';
          })}

          <div className="mt-8">
            <button
              className={`mb-4 w-full rounded-lg border py-2 font-semibold shadow-md ${
                optionBendungan === 'Dengan Bendungan'
                  ? 'border-blue-500 bg-blue-600 text-white shadow-blue-500'
                  : 'bg-white text-black'
              }`}
              onClick={() => {
                setOptionBendungan('Dengan Bendungan');
              }}
            >
              DENGAN BD
            </button>
            <button
              className={`mb-4 w-full rounded-lg border py-2 font-semibold shadow-md ${
                optionBendungan === 'Tanpa Bendungan'
                  ? 'border-blue-500 bg-blue-600 text-white shadow-blue-500'
                  : 'bg-white text-black'
              }`}
              onClick={() => {
                setOptionBendungan('Tanpa Bendungan');
              }}
            >
              TANPA BD
            </button>
          </div>

          <div className="mt-8">
            <button
              className={`mb-4 w-full rounded-lg border py-2 font-semibold shadow-md ${
                optionForecast === 'Near'
                  ? 'border-blue-500 bg-blue-600 text-white shadow-blue-500'
                  : 'bg-white text-black'
              }`}
              onClick={() => {
                setOptionForecast('Near');
              }}
            >
              NEAR
            </button>
            <button
              className={`mb-4 w-full rounded-lg border py-2 font-semibold shadow-md ${
                optionForecast === 'Forecast'
                  ? 'border-blue-500 bg-blue-600 text-white shadow-blue-500'
                  : 'bg-white text-black'
              }`}
              onClick={() => {
                setOptionForecast('Forecast');
              }}
            >
              FORECAST
            </button>
          </div>
        </div> */}

        <div>
          <div className="w-full overflow-hidden rounded-md border">
            <table className="w-full">
              <thead className="border-b">
                <tr className="bg-gray-100">
                  <th className="px-2 py-1 text-sm">No.</th>
                  <th className="px-2 py-1 text-start text-sm">Segmen</th>
                  <th className="px-2 py-1 text-start text-sm">
                    Curah Hujan (mm)
                  </th>
                  <th className="px-2 py-1 text-start text-sm">
                    Q<sub>Forecast</sub> (m<sup>3</sup>/dt)
                  </th>
                  <th className="px-2 py-1 text-start text-sm">
                    Q<sub>AWLR</sub> (m<sup>3</sup>/dt)
                  </th>
                  <th className="px-2 py-1 text-start text-sm">
                    Q<sub>FBC</sub> (m<sup>3</sup>/dt)
                  </th>
                  <th className="px-2 py-1 text-start text-sm">
                    % Q<sub>FBC</sub>
                  </th>
                  <th className="text-sm">Status</th>
                </tr>
              </thead>
              <tbody className="divide-y">
                {dataMatrix.map((d, i) => {
                  return (
                    <tr
                      key={i}
                      className="cursor-pointer hover:bg-gray-50"
                      onClick={() => {
                        console.log("click", [d.lat, d.lng]);
                        onClickShowMarker([d.lat, d.lng], i);
                      }}
                    >
                      <td className="px-2 py-1 text-center text-sm font-bold">
                        {i + 1}
                      </td>
                      <td className="px-2 py-1  text-sm font-medium">
                        {d.segment}
                      </td>
                      <td className="px-2 py-1  text-sm font-medium">
                        {d.curah_hujan}
                      </td>
                      <td className="px-2 py-1  text-sm font-medium">
                        {d.q_hitung ? d.q_hitung.toFixed(2) : 0}
                      </td>
                      <td className="px-2 py-1  text-sm font-medium">
                        {d.q_awlr ? d.q_awlr.toFixed(2) : 0}
                      </td>
                      <td className="px-2 py-1  text-sm font-medium">
                        {d.q_fbc}
                      </td>
                      <td className="px-2 py-1  text-sm font-medium">
                        {d.persen_fbc ? d.persen_fbc.toFixed(2) : 0}
                      </td>
                      <td
                        className="bg px-2  py-1 text-center text-xs font-medium"
                        style={{
                          backgroundColor: d.color,
                          color: d.text_color,
                        }}
                      >
                        {d.status}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex-1" ref={parentMapRef}>
          <div className="relative h-full overflow-hidden rounded-lg">
            <div className="absolute right-3 top-3 z-20 rounded-lg bg-blue-600 px-6 py-2 text-xl font-bold text-white shadow-md shadow-blue-500">
              {statusCuaca}
            </div>

            <div
              className="h-bank_full relative z-10 overflow-hidden rounded-lg bg-blue-200"
              style={{ height: "1044px" }}
            >
              <MapContainer
                ref={mapRef}
                center={[-8.544519, 116.103907]}
                zoom={14}
                scrollWheelZoom={false}
                style={{ height: "100%" }}
                bounds={[
                  [-8.551347039632656, 116.07035107293964],
                  [-8.528746583334872, 116.1517463874409],
                ]}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://api.mapbox.com/styles/v1/alwan27/ckzi1x0gi001f15qkhkpmmt4y/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWx3YW4yNyIsImEiOiJja21lcm9zbWkwemI1MnVuOGd4NzBzdmdmIn0.zH0KG2I5pGNjDXbhRhZEkg"
                />
                {/* <Marker position={[-8.543397, 116.097588]}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker> */}

                {dataMatrix.map((d, i) => {
                  return (
                    // <Marker position={[d.lat, d.lng]}>
                    //   <Popup>
                    //     A pretty CSS3 popup. <br /> Easily customizable.
                    //   </Popup>
                    // </Marker>

                    <Circle
                      key={i}
                      center={[d.lat, d.lng]}
                      radius={d.radius}
                      pathOptions={{ color: d.color, opacity: 0.9 }}
                      ref={(el) => (markerRef.current[i] = el)}
                    >
                      <Popup>
                        <table>
                          <tbody>
                            <tr>
                              <td className="pr-4 font-medium">Segmen</td>
                              <td>{d.segment}</td>
                            </tr>
                            <tr>
                              <td className="pr-4 font-medium">Curah Hujan</td>
                              <td>{d.curah_hujan}</td>
                            </tr>
                            <tr>
                              <td className="pr-4 font-medium">Q Hitung</td>
                              <td>{d.q_hitung.toFixed(3)}</td>
                            </tr>
                            <tr>
                              <td className="pr-4 font-medium">Q AWLR</td>
                              <td>{d.q_awlr.toFixed(3)}</td>
                            </tr>
                            <tr>
                              <td className="pr-4 font-medium">Q FBC</td>
                              <td>{d.q_fbc}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Popup>
                    </Circle>
                  );
                })}
              </MapContainer>
            </div>
          </div>

          {/* <div className="mt-8 w-full rounded-md border">
            <table className="w-full">
              <thead className="border-b">
                <tr className="bg-gray-100">
                  <th className="px-2 py-1 text-sm">No.</th>
                  <th className="px-2 py-1 text-start text-sm">Segemen</th>
                  <th className="px-2 py-1 text-start text-sm">
                    Curah Hujan (mm)
                  </th>
                  <th className="px-2 py-1 text-start text-sm">
                    Q<sub>Hitung</sub> (m<sup>3</sup>/dt)
                  </th>
                  <th className="px-2 py-1 text-start text-sm">
                    Q<sub>AWLR</sub> (m<sup>3</sup>/dt)
                  </th>
                  <th className="px-2 py-1 text-start text-sm">
                    Q<sub>FBC</sub> (m<sup>3</sup>/dt)
                  </th>
                  <th className="px-2 py-1 text-start text-sm">
                    % Q<sub>FBC</sub>
                  </th>
                  <th className="text-sm">Status</th>
                </tr>
              </thead>
              <tbody className="divide-y">
                {dataMatrix.map((d, i) => {
                  return (
                    <tr className="">
                      <td className="px-2 py-1 text-center text-sm">{i + 1}</td>
                      <td className="px-2 py-1  text-sm font-medium">
                        {d.segment}
                      </td>
                      <td className="px-2 py-1  text-sm font-medium">
                        {d.curah_hujan}
                      </td>
                      <td className="px-2 py-1  text-sm font-medium">
                        {d.q_hitung}
                      </td>
                      <td className="px-2 py-1  text-sm font-medium">
                        {d.q_awlr}
                      </td>
                      <td className="px-2 py-1  text-sm font-medium">
                        {d.q_fbc}
                      </td>
                      <td className="px-2 py-1  text-sm font-medium">
                        {d.persen_fbc}
                      </td>
                      <td
                        className="bg px-2  py-1 text-center text-sm font-medium"
                        style={{
                          backgroundColor: d.color,
                          color: d.text_color,
                        }}
                      >
                        {d.status}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default HomePage;
